import axios from "axios";

// import { baseUrl, accessToken, userCacheKey } from '../config/config';

// async function handleRequest(req) {
//   const token = localStorage.getItem(accessToken);
//   if (token) {
//     req.headers.Authorization = `Bearer ${token}`;
//   }
//   // req.headers.Accept = 'application/json';
//   // req.headers.timestamp = new Date().getTime();
//   // req.headers.macaddress = '12345567788';
//   return req;
// }

async function handleResponse(res) {
  if (res.status === 401 || (res.data && res.data.code === 401)) {
    // localStorage.removeItem(userCacheKey);
    // localStorage.removeItem(accessToken);
  }
  return res;
}

async function handleError(error) {
  if (
    error?.response?.status === 401 ||
    (error?.response?.data && error.response.data.code === 401)
  ) {
    // localStorage.removeItem(userCacheKey);
    // localStorage.removeItem(accessToken);
  }
  throw (
    error?.response?.data ?? { msg: error.message ?? "Something went wrong" }
  );
}

/**
 * This is used to modify the header request and relies on some header constraints
 * to generate some header fields
 */

// axios.interceptors.request.use(
//   async (req) => await handleRequest(req),
//   async (error) => await handleError(error)
// );

/**
 * This is used to modify response call to reprocess error 401 and generate new
 * token to use for new and current running request.
 */

axios.interceptors.response.use(
  async (res) => await handleResponse(res),
  handleError
);

const apiHandler = {
  get: async (baseUrl, basePath, path, config = {}) => {
    const response = await axios.get(`${baseUrl}/${basePath}/${path}`, config);
    return response?.data;
  },

  post: async (baseUrl, basePath, path, data = {}, config = {}) => {
    const url = `${baseUrl}/${basePath}/${path}`;
    const response = await axios.post(url, data, config);
    return response?.data;
  },

  put: async (baseUrl, basePath, path, data = {}, config = {}) => {
    const response = await axios
      .put(`${baseUrl}/${basePath}/${path}`, data, config)
      .catch((error) => {
        return error;
      });
    return response?.data;
  },

  patch: async (baseUrl, basePath, path, data = {}, config = {}) => {
    const response = await axios.patch(
      `${baseUrl}/${basePath}/${path}`,
      data,
      config
    );
    return response.data;
  },
  delete: async (baseUrl, basePath, path, data = {}, config = {}) => {
    const response = await axios
      .delete(`${baseUrl}/${basePath}/${path}`, data, config)
      .catch((error) => {
        return error;
      });
    return response?.data;
  },
};

export default apiHandler;
