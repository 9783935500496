import Swal from "sweetalert2";
import Cookies from "js-cookie";
import dayjs from "dayjs";
// Simulate delay
export const delay = (ms = 0) => {
	return new Promise((resolve) => setTimeout(resolve, ms));
};

export const truncate = (str, charLength = 10) =>
	str.length > charLength ? `${str.substring(0, charLength)}...` : str;

export const Toast = Swal.mixin({
	toast: true,
	position: "bottom-right",
	width: "40vw",
	showConfirmButton: false,
	timer: 5000,
	// timerProgressBar: true,
});

const runCheck = () => {
	const accessToken = Cookies.get("accessToken");

	if (!accessToken) {
		window.location.reload();
	}
};

export const checkAuthStatus = (interval = 1000) => {
	setInterval(runCheck, interval);
};

export const naira = Intl.NumberFormat("en-NG", {
	style: "currency",
	currency: "NGN",
	currencySign: 'accounting'
});

export const dollarUS = Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "USD",
});

export const dateFormat = (dateValue) => dayjs(dateValue).format("D MMM, YYYY");

export const formatCurrency = (value) => {
	if (value === "NGN") {
		return naira;
	} else if (value === "USD") {
		return dollarUS;
	} else {
		return naira;
	}
};

export function capitalizeFirstLetter(val) {
	const lowercase = val?.toLowerCase();
	return lowercase?.charAt(0).toUpperCase() + lowercase?.slice(1);
}
export const statusColor = (value) => {
	if (value === "PENDING") {
		return (
			<span
				// className="label label-warning"
				style={{
					background: "#FFE4C5",
					color: "#DD7700",
					borderRadius: 2,
					fontSize: 16,
				}}
			>
				{capitalizeFirstLetter(value)}
			</span>
		);
	} else if (value === "ACCEPTED") {
		return (
			<span
				className="label label-warning"
				style={{
					background: "#3BA1C1",
					color: "#ffffff",
					borderRadius: 2,
					fontSize: 16,
					// textTransform: "lowercase",
				}}
			>
				{capitalizeFirstLetter(value)}
			</span>
		);
	} else if (value === "PAID" || value === "DELIVERED") {
		return (
			<span
				className="label label-warning"
				style={{
					background: "#F2F9EB",
					color: "#6F972D",
					borderRadius: 2,
					fontSize: 16,
					// textTransform: "lowercase",
				}}
			>
				{capitalizeFirstLetter(value)}
			</span>
		);
	} else if (value === "INTRANSIT") {
		return (
			<span
				className="label label-warning"
				style={{
					background: "#4B9EDA",
					color: "#FFFFFF",
					borderRadius: 2,
					fontSize: 16,
					// textTransform: "lowercase",
				}}
			>
				{capitalizeFirstLetter(value)}
			</span>
		);
	} else if (value === "DECLINED" || value === "RETURNED") {
		return (
			<span
				className="label label-warning"
				style={{
					background: "#FFE0E6",
					color: "#E31D41",
					borderRadius: 2,
					fontSize: 16,
					// textTransform: "capitalize",
				}}
			>
				{capitalizeFirstLetter(value)}
			</span>
		);
	} else {
		return (
			<span
				className="label label-warning"
				style={{
					background: "#BE3DC7",
					color: "#FFFFFF",
					borderRadius: 2,
					fontSize: 16,
					// textTransform: "capitalize",
				}}
			>
				{capitalizeFirstLetter(value)}
			</span>
		);
	}
};

export const compare = (a, b) => {
	if (a.to < b.to) {
		return -1;
	}
	if (a.to > b.to) {
		return 1;
	}
	return 0;
};

export const compareRev = (a, b) => {
	if (a.totalRevenue > b.totalRevenue) {
		return -1;
	}
	if (a.totalRevenue < b.totalRevenue) {
		return 1;
	}
	return 0;
};

export const currencies = [
	{
		id: "NGN",
		name: "₦",
	},
	// {
	// 	id: "USD",
	// 	name: "$",
	// },
	// {
	// 	id: "EUR",
	// 	name: "€",
	// },
	// {
	// 	id: "BTC",
	// 	name: "฿",
	// },
	// {
	// 	id: "JPY",
	// 	name: "¥",
	// },
];
