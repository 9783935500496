import { BASE_USER_URL, BASE_USER_PATH } from '../config/envVars';
import apiHandler from './apiHandler';

export const registerUser = async (data) =>
  apiHandler.post(BASE_USER_URL.USER_SRV, BASE_USER_PATH.USER_SRV, 'auth/register', data);

export const loginUser = async (data) =>
  apiHandler.post(BASE_USER_URL.USER_SRV, BASE_USER_PATH.USER_SRV, 'auth/login', data);

export const logout = async (data) => {};

export const getUserById = async (data) => {};
export const updateUser = async (data) => {};

export const forgotPassword = async (data) => {};
export const resetUserPassword = async (data) => {};
export const refreshAuthToken =  (data) =>
  apiHandler.post(BASE_USER_URL.USER_SRV, BASE_USER_PATH.USER_SRV, 'auth/refresh-token', data);

export const verifyEmail = async (data) => {};

export const resendVerificationEmail = async (data) => {};
