import { atom } from 'recoil';
import Cookie from 'js-cookie';
import { ROOT_DOMAIN } from '../config/envVars';

const cookieConfig = {
	accessToken: "accessToken",
	accessTokenConfig: {
		domain: ROOT_DOMAIN,
		secure: process.env.NODE_ENV === "production",
	},

	refreshToken: "refreshToken",
	refreshTokenConfig: {
		domain: ROOT_DOMAIN,
		secure: process.env.NODE_ENV === "production",
	},

	user: "user",
	userConfig: {
		domain: ROOT_DOMAIN,
		secure: process.env.NODE_ENV === "production",
	},
};
const user = Cookie.get('user');
const activeUser = () => {
	try {
		return user ? JSON.parse(user) : null;
	} catch (error) {
		Cookie.remove(
			cookieConfig.refreshToken,

			cookieConfig.refreshTokenConfig
		);
		Cookie.remove(
			cookieConfig.accessToken,

			cookieConfig.accessTokenConfig
		);
		Cookie.remove(cookieConfig.user, cookieConfig.userConfig);

		return null;
	}
};

export const authAtom = atom({
  key: 'authAtom',
  default: Cookie.get('accessToken'),
});
export const userAtom = atom({
  key: 'userAtom',
  default: activeUser(),
});

export const isSellerAtom = atom({
  key: 'isSellerAtom',
  default: false,
});

export const currentFormStepAtom = atom({
  key: 'currentFormStepAtom',
  default: 0,
});
