import { ROOT_DOMAIN } from './envVars';

export const logger = {
  info(...args) {
    if (process.env.NODE_ENV !== 'production') {
      console.log(...args);
    }
  },

  error(...args) {
    if (process.env.NODE_ENV !== 'production') {
      console.error(...args);
    }
  },
};

export const cookieConfig = {
  accessToken: 'accessToken',
  accessTokenConfig: {
    domain: ROOT_DOMAIN,
    secure: process.env.NODE_ENV === 'production',
  },

  refreshToken: 'refreshToken',
  refreshTokenConfig: {
    domain: ROOT_DOMAIN,
    secure: process.env.NODE_ENV === 'production',
  },

  user: 'user',
  userConfig: {
    domain: ROOT_DOMAIN,
    secure: process.env.NODE_ENV === 'production',
  },

  store: 'store',
  storeConfig: {
    domain: ROOT_DOMAIN,
    secure: process.env.NODE_ENV === 'production',
  },
};


