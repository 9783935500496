export const inventaryServiceBaseUrl = process.env.REACT_APP_INVENTORY_API_URL;
export const userServiceBaseUrl = process.env.REACT_APP_USER_API_URL;
export const accessTokenKey = process.env.REACT_APP_ACCESS_TOKEN;
export const refreshTokenKey = process.env.REACT_APP_REFRESH_TOKEN;
export const userCacheKey = process.env.REACT_APP_USER;
export const userServiceFrontEnd = process.env.REACT_APP_USER_SERVICE_FRONTEND;

export const ROOT_DOMAIN = process.env.REACT_APP_ROOT_DOMAIN;
export const STORE_URL = process.env.REACT_APP_STORE_URL;
export const MIN_PAY_URL = process.env.REACT_APP_MIN_PAY_URL;

//Gateway URL 
export const gateWayUrl = process.env.REACT_APP_API_GATEWAY;
export const userBasePath = process.env.REACT_APP_USER_BASE_PATH;
export const inventoryBasePath = process.env.REACT_APP_INVENTORY_BASE_PATH;
export const orderBasePath = process.env.REACT_APP_ORDER_BASE_PATH;

export const BASE_URL = {
  INVENTARY_SRV: gateWayUrl,
};

export const BASE_PATH = {
  INVENTARY_SRV: inventoryBasePath,
};

export const BASE_USER_URL = {
  USER_SRV: gateWayUrl,
};

export const BASE_USER_PATH = {
  USER_SRV: userBasePath,
};


export const BASE_ORDER_URL = {
  ORDER_SRV: gateWayUrl,
};

export const BASE_ORDER_PATH = {
  ORDER_SRV: orderBasePath,
};

