import React, { Suspense, useEffect } from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import '../styles/main.scss';
import { refreshAuthToken } from '../api/userApi';
import { userServiceFrontEnd } from '../config/envVars';
import { useRecoilState } from 'recoil';
import { authAtom } from '../recoil/atom';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { QueryClient, QueryClientProvider } from 'react-query';
import Cookie from 'js-cookie';
import { cookieConfig } from '../config/config';
import { checkAuthStatus } from '../utils/Utils';
import { ROOT_DOMAIN } from '../config/envVars';
import LinearProgress from '@mui/material/LinearProgress';






const LazyAccountOverview = React.lazy(() =>
  import('../pages/AccountOverview')
);
const LazyManageStore = React.lazy(() => import("./ManageStore/StoreMain"));

// const LazyManageStore = React.lazy(() => import("./ManageStore/storeDetails"));




// const LazyManageStorePage = React.lazy(() =>
//   import('./ManageStore/storeDetails')
// );

const LazyCustom404 = React.lazy(() =>
  import('./404')
);


const LazyStoreFormMui = React.lazy(() => import('./ManageStore/StoreFormMaterialUI'));


const LazyFactoryDetails = React.lazy(() =>
  import('./ManageStore/factoryDetails')
);
const LazyProductionCertificate = React.lazy(() =>
  import('./ManageStore/productionCertificate')
);
const LazyTradeUnion = React.lazy(() =>
  import('./ManageStore/tradeAssociation')
);

const LazyNoProduct = React.lazy(() => import('./ManageProduct/NoProduct'));
const ProductMain = React.lazy(() => import('./ManageProduct/ProductMain'));
const ProductFormV1 = React.lazy(() => import('./ManageProduct/CreateProduct/ProductInfoForm'));
const LazyProductOverview = React.lazy(() =>
  import('./ManageProduct/ProductOverview')
);
const LazyProductDetails = React.lazy(() =>
  import('./ManageProduct/ProductDetails')
);
const LazyHighestRevenue = React.lazy(() =>
  import('./ManageProduct/HighestRevenueDetails')
);
const LazyListProducts = React.lazy(() =>
  import('./ManageProduct/ListProducts')
);

const LazyManageProduct = React.lazy(() =>
  import('./ManageProduct/ProductReport')
);

// const LazyProductInfo = React.lazy(() =>
//   import('./ManageProduct/CreateProduct/ProductInformation')
// );

const LazyProductInfo = React.lazy(() =>
  import('./ManageProduct/CreateProduct/ProductForm')
);

const LazyProductInfoUI = React.lazy(() =>
  import('./ManageProduct/CreateProduct/ProductFormUI')
);

const LazyProductLogistics = React.lazy(() =>
  import('./ManageProduct/CreateProduct/ProductLogistics')
);

const LazyProductSample = React.lazy(() =>
  import('./ManageProduct/CreateProduct/ProductSample')
);
const LazyProductConfiguration = React.lazy(() =>
  import('./ManageProduct/CreateProduct/ProductConfiguration')
);

const LazyProductModelImage = React.lazy(() =>
  import('./ManageProduct/CreateProduct/ProductModelImage')
);

const LazyProductCoverImage = React.lazy(() =>
  import('./ManageProduct/CreateProduct/ProductCoverImage')
);

const LazyProductPrice = React.lazy(() =>
  import('./ManageProduct/CreateProduct/ProductPrice')
);

const LazyOrderItemCard = React.lazy(() =>
  import('../components/card/OrderItemCard')
);

const LazyAdditionalInfo = React.lazy(() =>
  import('./ManageProduct/CreateProduct/AdditionalInformation')
);
const LazyProductPackaging = React.lazy(() =>
  import('./ManageProduct/CreateProduct/ProductPackaging')
);

const LazyNoStaff = React.lazy(() => import('./ManageStaff/NoStaff'));
const LazyListRoles = React.lazy(() => import('./ManageStaff/ListRoles'));
const LazyListStaff = React.lazy(() => import('./ManageStaff/ListStaff'));

const LazyManageInvoice = React.lazy(() =>
  import('./ManageInvoice/InvoiceOverview')
);

const LazySettings = React.lazy(() => import('./Settings/Index'));

const LazyListOrder = React.lazy(() => import('./ManageOrders/ListOrders'));
const LazyOrderDetails = React.lazy(() =>
  import('./ManageOrders/OrderDetails')
);


const LazyNoDistributor = React.lazy(() =>
  import('./ManageDistributors/NoDistributor')
);

const LazyDistributorForm = React.lazy(() =>
  import('./ManageDistributors/DistributorForm')
);

const LazyAddNewDistributor = React.lazy(() =>
  import('./ManageDistributors/AddNewDistributor')
);

const LazyDistributorApplicationDetails = React.lazy(() =>
  import('./ManageDistributors/DistributorApplicationDetails')
);

const LazyAllaccount = React.lazy(() =>
  import('./ManageStore/allAccount')
);

const LazyViewDistributors = React.lazy(() =>
  import('./ManageDistributors/ViewDistributors')
);

const LazyPendingDistributor = React.lazy(() =>
  import('./ManageDistributors/DistributorPending')
);


const LazyDistributorInvitationDetails = React.lazy(() =>
  import('./ManageDistributors/DistributorInvitationDetails')
);

const LazyDistributorInvitation = React.lazy(() =>
  import('./ManageDistributors/DistributorInvitation')
);

const LazyDistributorApplication = React.lazy(() =>
  import('./ManageDistributors/DistributorApplication')
);


// DistributorInvitationDetails
// const LazyDistributorPendingApplication = React.lazy(() =>
//   import('./ManageDistributors/DistributorPendingInvitation')
// );

const LazyListDistributors = React.lazy(() =>
  import('./ManageDistributors/ListDistributors')
);
const LazyDistributorDetails = React.lazy(() =>
  import('./ManageDistributors/DistributorDetails')
);

const LazyDistributorConfigureDocument = React.lazy(() =>
  import('./ManageDistributors/ConfigureDocument')
);

const LazyDistributorConfigureDocumentTable = React.lazy(() =>
  import('./ManageDistributors/ConfigureDistributorTable')
);

const LazyDistributorDocument = React.lazy(() =>
  import('./ManageDistributors/DistributorDocument')
);


const LazyOnboardDistributor = React.lazy(() =>
  import('./ManageDistributors/OnboardDistributor')
);


const LazyDistributorMessages = React.lazy(() =>
  import('./ManageDistributors/Messages')
);

const LazyNoManufacturer = React.lazy(() =>
  import('./ManageManufacturers/NoManufacturer')
);

const LazyViewManufacturers = React.lazy(() =>
  import('./ManageManufacturers/ViewManufacturers')
);


// const LazyViewManufacturerPending = React.lazy(() =>
//   import('./ManageManufacturers/ManufacturerPending')
// );
// ManufacturerForm
const LazyViewManufacturerForm = React.lazy(() =>
  import('./ManageManufacturers/ManufacturerForm')
);
const LazyViewManufacturerAvaliable = React.lazy(() =>
  import('./ManageManufacturers/AvaliableManufacturer')
);

const LazyManufacturerPendingInvitation = React.lazy(() =>
  import('./ManageManufacturers/ManufacturerPendingInvitation')
);

const LazyManufacturerPendingApplication = React.lazy(() =>
  import('./ManageManufacturers/ManufacturerPendingApplication')
);


const LazyListManufacturers = React.lazy(() =>
  import('./ManageManufacturers/ListManufacturers')
);

const LazyManufacturerInvitationDetails = React.lazy(() =>
  import('./ManageManufacturers/ManufacturerInvitationDetails')
);

const lazyManufactureApplicationDetails = React.lazy(() =>
  import('./ManageManufacturers/ManufacturerApplicationDetails')
);

const LazyLoadInvoiceDetail = React.lazy(() =>
  import('./ManageInvoice/invoiceDetails')
);

const LazyProductsLogo = React.lazy(() =>
  import('./ManageStore/storeLogo')
);

const LazyLocation = React.lazy(() =>
  import('./ManageStore/storeLocation')
);

const LazyStoreContact = React.lazy(() =>
  import('./ManageStore/storeContact')
);

const LazyStoreForm = React.lazy(() =>
  import('./ManageStore/StoreForm')
);

// const LazyStoreDetails = React.lazy(() =>
//   import('../pages/ManageStore/storeDetails')
// );


function App() {
  const history = useHistory();
  const [auth, setAuth] = useRecoilState(authAtom);

  useEffect(() => {
    if (auth === null || auth === undefined) {
      window.location = `${userServiceFrontEnd}/?callback_url=${window.location.href}`;
    }
  }, [auth, history]);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnReconnect: true,
      },
    },
  });

  async function handleRequest(req) {
    if (auth) {
      req.headers.Authorization = `Bearer ${auth}`;
      //    logger.info('AUTH HEADER', req.headers.Authorization);
    }
    req.headers.Accept = 'application/json';
    req.headers.timestamp = new Date().getTime();
    return req;
  }

  function getRefereshToken() {
    const refreshToken = Cookie.get('refreshToken');
    refreshAuthToken({ refreshToken: refreshToken }).then(
      (data) => {
        setAuth(data?.data?.accessToken);
        try {
          Cookie.set(
            cookieConfig.refreshToken,
            data.data.refreshToken,
            cookieConfig.refreshTokenConfig
          );
          Cookie.set(
            cookieConfig.accessToken,
            data.data.accessToken,
            cookieConfig.accessTokenConfig
          );
          Cookie.set(
            cookieConfig.user,
            data.data.user,
            cookieConfig.userConfig
          );
        } catch (error) { }
      },
      (error) => {
        Cookie.remove('user', { domain: ROOT_DOMAIN });
        Cookie.remove('accessToken', { domain: ROOT_DOMAIN });
        Cookie.remove('refreshToken', { domain: ROOT_DOMAIN });
        window.location.reload();
      }
    );
  }

  /**
   * This is used to modify the header request and relies on some header constraints
   * to generate some header fields
   */
  axios.interceptors.request.use(async (req) => await handleRequest(req));

  axios.interceptors.response.use(
    async (res) => await handleResponse(res),
    async (error) => await handleError(error)
  );

  async function handleResponse(response) {
    // if (res.status === 401 || res?.data?.code === 401) {
    //   // const res = await refreshAuthToken();
    //   // Set Auth
    //   // localStorage.setItem(accessTokenKey, res.data.accessToken);
    //   // setAuth(res.data.accessToken);
    //   //  logger.info('New access token', res.data.accessToken);
    // }

    // return Promise.resolve(response);
    return response;
  }

  async function handleError(error) {
    if (error.code === 401) {
      Cookie.remove('user', { domain: ROOT_DOMAIN });
      Cookie.remove('accessToken', { domain: ROOT_DOMAIN });
      Cookie.remove('refreshToken', { domain: ROOT_DOMAIN });
      window.location.reload();
    } else {
      return Promise.reject(error);
    }
  }

  function calcNextTenMinute() {
    var now = new Date();
    var next = new Date();
    next.setSeconds(0);
    next.setMilliseconds(0);
    var tenPeriodStart = Math.round(now.getMinutes() / 10) * 10;
    next.setMinutes(tenPeriodStart + 10);
    return next - now;
  }

  function runRefreshToken() {
    setTimeout(function () {
      getRefereshToken();
      runRefreshToken();
    }, calcNextTenMinute());
  }

  runRefreshToken();

  if (typeof window !== 'undefined') {
    checkAuthStatus();
  }
  // <CircularProgress color="success" />
  <LinearProgress color="success" />
  return (

    <Suspense fallback={<LinearProgress />}>
      {/* <RecoilRoot> */}
      <QueryClientProvider client={queryClient}>
        <Router>
          <Switch>
            <Route exact path="/" component={LazyAccountOverview} />{' '}
            {/* <Route exact path='/mat' component={LazyStoreFormMui} /> */}
            {/* <Route exact path='/crop' component={LazyCropper} /> */}
            {/* <Route exact path="/tree" component={LazySimpleTree} />{' '} */}
            <Route exact path="/settings" component={LazySettings} />

            <Route exact path="/dashboard" component={LazyAllaccount} />

            {/* <Route exact path="/manage-contact" component={Store} />/}
            
            {/* Manage Products */}

            <Route exact path="/product/v1" component={ProductFormV1} />{' '}
            <Route exact path="/manage-product" component={ProductMain} />{' '}
            {/* <Route exact path="/
            " component={StoreDetails} />{' '} */}
            <Route
              exact
              path="/manage-product/product-overview"
              component={LazyProductOverview}
            />{' '}

            <Route
              exact
              path="/manage-product/product-details"
              component={LazyProductDetails}
            />{' '}
            <Route
              exact
              path="/manage-product/highest-revenue"
              component={LazyHighestRevenue}
            />{' '}
            <Route
              exact
              path="/manage-product/list-products"
              component={LazyListProducts}
            />

            <Route
              exact
              path="/manage-product/report"
              component={LazyManageProduct}
            />


            <Route exact path="/manage-product" component={ProductMain} />
            <Route
              exact
              path="/ManageProduct/NoProduct"
              component={LazyNoProduct}
            />
            {/* Create Product */}{' '}
            {/* <Route
              exact
              path="/create-product/product-info"
              component={LazyProductInfo}
            />{' '} */}
            <Route
              exact
              path="/create-product/product-info"
              component={LazyProductInfoUI}
            />{' '}

            <Route
              exact
              path="/create-product/product-cover-image"
              component={LazyProductCoverImage}
            />{' '}
            <Route
              exact
              path="/create-product/product-sample"
              component={LazyProductSample}
            />{' '}

            <Route
              exact
              path="/create-product/product-config"
              component={LazyProductConfiguration}
            />{' '}

            <Route
              exact
              path="/create-product/product-model"
              component={LazyProductModelImage}
            />{' '}


            <Route
              exact
              path="/create-product/product-price"
              component={LazyProductPrice}
            />{' '}
            <Route
              exact
              path="/create-product/product-logistics"
              component={LazyProductLogistics}
            />{' '}

            <Route
              exact
              path="/create-product/additional-info"
              component={LazyAdditionalInfo}
            />{' '}
            <Route
              exact
              path="/create-product/product-packaging"
              component={LazyProductPackaging}
            />
            {/* Manage Store */}

            <Route
              exact
              path="/create-store/store-form"
              component={LazyStoreForm}
            />
            <Route
              exact
              path="/create-store/store-details"
              // component={LazyManageStorePage}

              // component={LazyStoreForm}
              component={LazyStoreFormMui}
            />
            <Route exact path="/manage-store" component={LazyManageStore} />{' '}

            <Route
              exact
              path="/create-store/store-logo"
              component={LazyProductsLogo}
            />{' '}

            <Route
              exact
              path="/create-store/store-contact"
              component={LazyStoreContact}
            />{' '}

            <Route
              exact
              path="/create-store/store-location"
              component={LazyLocation}
            />{' '}

            <Route
              exact
              path="/create-store/factory-details"
              component={LazyFactoryDetails}
            />{' '}
            <Route
              exact
              path="/create-store/production-certificate"
              component={LazyProductionCertificate}
            />{' '}
            <Route
              exact
              path="/create-store/trade-union"
              component={LazyTradeUnion}
            />
            {/* Manage Staff */}
            <Route
              exact
              path="/manage-staff/no-staff"
              component={LazyNoStaff}
            />{' '}
            <Route
              exact
              path="/manage-staff/list-roles"
              component={LazyListRoles}
            />{' '}
            <Route
              exact
              path="/manage-staff/list-staff"
              component={LazyListStaff}
            />
            {/* Manage Invoice */}
            <Route
              exact
              path="/manage-invoice"
              component={LazyManageInvoice}
            />{' '}
            {/* Manage Order */}
            <Route
              exact
              path="/manage-order/list-order"
              component={LazyListOrder}
            />
            <Route
              exact
              path="/manage-order/order-details"
              component={LazyOrderDetails}
            />
            {/* Manage Distributors */}
            <Route
              exact
              path="/manage-distributors/no-distributor"
              component={LazyNoDistributor}
            />


            <Route
              exact
              path="/manage-distributors/view"
              component={LazyViewDistributors}
            />

            <Route
              exact
              path="/manage-distributors/form"
              component={LazyDistributorForm}
            />

            <Route
              exact
              path="/manage-distributors/list"
              component={LazyListDistributors}
            />
            <Route
              exact
              path="/manage-distributors/details"
              component={LazyDistributorDetails}
            />
            <Route
              exact
              path="/manage-distributors/add"
              component={LazyAddNewDistributor}
            />

            <Route
              exact
              path="/manage-distributors/invitation/details"
              component={LazyDistributorInvitationDetails}
            />


            <Route
              exact
              path="/manage-distributors/invitation"
              component={LazyDistributorInvitation}
            />


            <Route
              exact
              path="/manage-distributors/application"
              component={LazyDistributorApplication}
            />
            <Route
              exact
              path="/manage-distributors/application/details/:id"
              component={LazyDistributorApplicationDetails}
            />
            <Route
              exact
              path="/manage-distributors/messages"
              component={LazyDistributorMessages}
            />
            <Route
              exact
              path="/manage-distributors/configure"
              component={LazyDistributorConfigureDocument}
            />
            <Route
              exact
              path="/manage-distributors/configure-table"
              component={LazyDistributorConfigureDocumentTable}
            />

            <Route
              exact
              path="/manage-distributors/document"
              component={LazyDistributorDocument}
            />

            <Route
              exact
              path="/manage-distributors/pending"
              component={LazyPendingDistributor}
            />
            <Route
              exact
              path="/manage-distributors/onboarding"
              component={LazyOnboardDistributor}
            />
            {/* Manage Manufacturers */}
            <Route
              exact
              path="/manage-manufacturers/no-manufacturer"
              component={LazyNoManufacturer}
            />
            <Route
              exact
              path="/manage-manufacturers/view"
              component={LazyViewManufacturers}
            />
            {/* LazyViewManufacturerForm */}
            <Route
              exact
              path="/manage-manufacturers/:id"
              component={LazyViewManufacturerAvaliable}
            />

            <Route
              exact
              path="/manage-manufacturers/manufacturer/:form"
              component={LazyViewManufacturerForm}
            />


            <Route
              exact
              path="/manage-manufacturers/list"
              component={LazyListManufacturers}
            />


            {/* <Route
              exact
              path="/manage-manufacturers/application"
              component={LazyViewManufacturerPending}
            /> */}

            <Route
              exact
              path="/manage-manufacturers/pending/invitation/:manufacturerId"
              component={LazyManufacturerPendingInvitation}
            />


            <Route
              exact
              path="/manage-manufacturers/pending/application/:manufacturerId"
              component={LazyManufacturerPendingApplication}
            />
            <Route
              exact
              path="/manage-manufacturers/invitation/details"
              component={LazyManufacturerInvitationDetails}
            />
            {/*  */}

            <Route
              exact
              path="/manage-manufacturers/application/details/:distributorId"
              component={lazyManufactureApplicationDetails}
            />
            <Route
              exact
              path="/manage-order/order-details"
              component={LazyOrderItemCard}
            />
            <Route
              exact
              path="/invoice-details"
              component={LazyLoadInvoiceDetail}
            />

            <Route
              component={LazyCustom404}
            />


            {/* <Route exact path="/create-store" component={Create} /> */}
          </Switch>{' '}
        </Router>{' '}
        <ReactQueryDevtools initialIsOpen={false} />{' '}
      </QueryClientProvider>{' '}
      {/* </RecoilRoot> {" "} */}
    </Suspense>

  );
}

export default App;
// const LazyListOrder = React.lazy(() =>
//     import ('./ManageOrders/ListOrders')
// );
// const LazyOrderDetails = React.lazy(() =>
//     import ('./ManageOrders/OrderDetails')
// );

// const LazySettings = React.lazy(() =>
//     import ('./Settings/Index'));

//     export default App;
